/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--brand: #23cbd5;
	--secondary: #8b5cf6;
	--slider-track-color: #d8e0f3;
	--area-gradient-base: var(--color-brand);
}

.dark {
	color-scheme: dark;
	--brand: #23cbd5;
	--slider-track-color: #344157;
	--area-gradient-base: var(--color-brand);
}

@layer utilities {
	.button-loading {
		@apply relative !text-transparent after:absolute after:h-5 after:w-5 after:rounded-full after:border-[0.20rem] after:border-slate-300 after:border-t-gray-100 after:opacity-90 after:animate-spin;
	}
	.loading-row {
		@apply relative !text-transparent after:absolute after:right-5 after:h-5 after:w-5 after:rounded-full after:border-[0.20rem] after:border-slate-300 after:border-t-gray-100 after:opacity-90 after:animate-spin;
	}
}

@layer components {
	[tooltip] {
		position: relative;
		cursor: pointer;
	}
	
	.ng-tooltip {
		position: absolute;
		max-width: 150px;
		font-size: 0.75rem;
		text-align: center;
		color: white;
		padding: 4px 8px;
		background: var(--brand);
		border-radius: 4px;
		z-index: 1000;
		opacity: 0;
		transition: opacity 0.3s;
	}
  
	.ng-tooltip-arrow::after {
		content: '';
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 8px;
		border-style: solid;
		border-color: var(--brand) transparent transparent transparent;
	}
  
	.ng-tooltip-show {
		opacity: 1;
	}
}

.dot-loader, .dot-loader:before, .dot-loader:after {
	border-radius: 50%;
	width: 2.5em;
	height: 2.5em;
	animation-fill-mode: both;
	animation: bblFadInOut 1.8s infinite ease-in-out;
}
.dot-loader {
	color: #979797;
	font-size: 4px;
	position: relative;
	text-indent: -9999em;
	transform: translateZ(0);
	animation-delay: -0.16s;
}
.dot-loader:before,
.dot-loader:after {
	content: '';
	position: absolute;
	top: 0;
}
.dot-loader:before {
	left: -3.5em;
	animation-delay: -0.32s;
}
.dot-loader:after {
	left: 3.5em;
}

@keyframes bblFadInOut {
	0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
	40% { box-shadow: 0 2.5em 0 0 }
}

.circle-loader {
	width: 28px;
	height: 28px;
	border: 5px solid #fff;
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

.dark-circle-loader {
	width: 16px;
	height: 16px;
	border: 3px solid #979797;
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

.brand-circle-loader {
	width: 32px;
	height: 32px;
	border: 3px solid #23cbd5;
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.toast-success {
	background-color: #27AE60 !important
}

.toast-error {
	background-color: #EB5757 !important
}

.toast-warning {
	background-color: #E2B93B !important
}

.show-invalid .ng-invalid {
	border-color: #EB5757 !important;
}

.rounded-r-config {
	@apply rounded-r-2xl;
}

.rounded-l-config {
	@apply rounded-l-2xl;
}

.provider-card {
	border-width: 1px;
}


/* Remove the arrows for type="number" for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Remove the arrows for type="number" for Firefox */
input[type=number] {
	-moz-appearance: textfield;
}
